import * as React from 'react';
import Helmet from 'react-helmet';

const Definitions = () => {

  return (
    <>
      <Helmet title="Definitions" />
      <section className="contact-page">
        <div className="inner inner-padding">
          <h2 className="text-center">Definitions</h2>
        </div>
      </section>
      <section className="relative mb-10">
        <div className="inner inner-padding definitions-wrapper">
          <button><a className="mb-8 block flex items-center lg:hidden btn btn-green ml-2 px-6" href="/BlueValueDefinitions.pdf" download="BlueValueDefinitions">Download PDF</a></button>
          <a className="link-wrap" href="/BlueValueDefinitions.pdf" download="BlueValueDefinitions">
            <div className="blocker"></div>
            <iframe className="wmiframe Main-iFrameDefinitions" id="main_iFrameDefinitions" src="/BlueValueDefinitions.pdf">
            </iframe>
          </a>
        </div>
      </section>
    </>
  );
};

export default Definitions;
